<template>
  <CRow>
    <CCol sm="12">

      <CCard>
        <CCardHeader>
          <CIcon name="cil-magnifying-glass"/>
          &nbsp;
          <h5>Suche nach Reporten</h5>
        </CCardHeader>

        <CCardBody>

          <Suche v-model="suchenach" :router-push="false"/>

          <CCard v-if="protokoll">
            <CCardBody>

              <CRow>
                <CCol sm="2">
                  <strong>Protokoll</strong>
                  <br/>
                  <small>ID: {{protokoll.protokollid}}</small>
                  <span v-if="protokoll.rechnungsnummer !== ''" class="d-block">
                    <small>Rg. {{protokoll.rechnungsnummer}}</small>
                  </span>
                </CCol>

                <CCol sm="5" v-if="protokoll.kunde">
                  <em>Kunde</em><br/>
                  {{protokoll.kunde.name1}}
                  <span v-if="protokoll.projekt">
                    | {{protokoll.projekt.name}}
                  </span>
                </CCol>

                <CCol sm="1">
                  <CButton
                    color="info"
                    class="btn-block"
                    v-on:click="print()"
                  >
                    <CIcon name="cil-print"/>
                  </CButton>
                </CCol>

                <CCol sm="4" class="text-right" v-if="protokoll.arbeitszeiten">
                  <div v-for="(arbeitszeit, index) in protokoll.arbeitszeiten" v-bind:key="index" class="arbeitszeit">
                    <!-- Mitarbeiter sieht im Protokoll nur die eigenen Stunden -->
                    <!-- HINT: Beim Druck sieht der Mitarbeiter alle Stunden -->
                    <div v-if="arbeitszeit.mitarbeiter.mitarbeiterid == user.userid">
                      {{arbeitszeit.datum|moment('DD.MM.YYYY')}} ->
                      <span v-if="!protokoll.tageskalender" style="color: red; font-weight: bold;">
                        {{arbeitszeit.dauer|number('0.00')}}h
                      </span>
                      <span v-else>
                        {{arbeitszeit.dauer|number('0.00')}}h
                      </span>
                    </div>
                  </div>
                </CCol>

              </CRow>

            </CCardBody>
          </CCard>

        </CCardBody>
      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import Suche from '@/views/Tageskalender/components/Suche'

export default {
  name: 'tageskalender-suche',
  components: {
    Suche
  },
  props: {
    suchwort: String
  },
  mounted () {
    if (this.suchwort) {
      this.suchenach = this.suchwort
      this.suchProtokoll()
    }
  },
  data () {
    return {
      suchenach: '',
      protokoll: null
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    }
  },
  watch: {
    suchenach: function () {
      this.suchProtokoll()
    }
  },
  methods: {
    suchProtokoll () {
      // Aufruf der Suche
      Vue.axios.get('/tageskalender/suche/' + this.suchenach)
        .then((response) => {
          this.protokoll = response.data
        })
    },
    print () {
      if (this.protokoll) {
        const routeData = this.$router.resolve({ path: `/print/${this.protokoll.protokollid}` })
        window.open(routeData.href, '_blank')
      }
    }
  }
}
</script>
