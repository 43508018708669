<template>
  <CRow>
    <CCol sm="12">

      <CCard>
        <CCardBody>

          <CRow>
            <CCol sm="3" class="font-weight-bold">
              ID des Protokolls:
            </CCol>
            <CCol sm="6">
              <CInput
                placeholder="Suchwort"
                v-model="suchwort"
              />
            </CCol>
            <CCol sm="3">
              <CButton
                color="info"
                class="btn-block"
                v-on:click="search()"
              >
                <CIcon name="cil-magnifying-glass"/>
                &nbsp;
                Suchen
              </CButton>
            </CCol>
          </CRow>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'tageskalender-components-suche',
  props: {
    value: String,
    routerPush: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      suchwort: ''
    }
  },
  mounted () {
    if (this.value) {
      this.suchwort = this.value
    } else {
      this.suchwort = ''
    }
  },
  watch: {
    value: function () {
      if (this.value) {
        this.suchwort = this.value
      }
    }
  },
  methods: {
    search () {
      if (this.suchwort) {
        const suchwort = this.suchwort
        this.$emit('input', suchwort)
        if (this.routerPush) {
          this.$router.push({ path: `/tageskalender/suche/${suchwort}` })
        }
      }
    }
  }
}
</script>
